import React, { useState } from "react";

import Racer from "../../Assets/Racer.js";
import SpeedCage from "../../Assets/SpeedCage";

import SegmentDetails from "./SegmentDetails.js";

import Styles from "./Segment.module.scss";

const Segment = ({ seasonSegments, ongoingSegment, segment, index }) => {
  // State to track which segment is currently expanded
  const [expandedSegment, setExpandedSegment] = useState(
    ongoingSegment.segmentNumber
  );

  const handleAccordionToggle = (segmentNumber) => {
    // Toggle the expanded segment: collapse if the same segment is clicked
    setExpandedSegment((prev) =>
      prev === segmentNumber ? null : segmentNumber
    );
  };

  const calculateTimeLeft = (endDate) => {
    if (!endDate) return {};

    const difference = new Date(endDate) - new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  return (
    <div key={segment.id} className={Styles.segment}>
      {new Date(segment.startDate) <= new Date() ? (
        // segment has started or is in the past...
        <div
          className={Styles.stats}
          onClick={() => handleAccordionToggle(segment.segmentNumber)}
        >
          <div className={Styles.title}>
            <h4>Week {segment.segmentNumber}</h4>
          </div>

          <div className={Styles.challenges}>
            <div
              className={`${Styles.challenge} ${
                segment.seasonChallenges.find(
                  (challenge) =>
                    challenge.challengeType === "racer" &&
                    challenge.seasonChallengeProgresses.length > 0
                )
                  ? Styles.completed
                  : ""
              }`}
            >
              <Racer />
            </div>
            <div
              className={`${Styles.challenge} ${
                segment.seasonChallenges.find(
                  (challenge) =>
                    challenge.challengeType === "speedCage" &&
                    challenge.seasonChallengeProgresses.length > 0
                )
                  ? Styles.completed
                  : ""
              }`}
            >
              <SpeedCage />
            </div>
          </div>

          <div className={Styles.streaks}>
            {seasonSegments.map((segment) => (
              <span
                key={segment.segmentNumber}
                className={
                  segment.seasonSegmentProgresses[0]?.streakCompleted &&
                  segment.segmentNumber <= index + 1
                    ? Styles.completed
                    : ""
                }
              ></span>
            ))}
          </div>

          {!(expandedSegment === segment.segmentNumber) && (
            <>
              <div className={Styles.addedPoints}>
                {segment?.seasonSegmentProgresses[0]?.totalPoints
                  ? `+ ${segment.seasonSegmentProgresses[0].totalPoints}`
                  : "+ 0"}
              </div>

              <div className={Styles.totalPoints}>
                {seasonSegments.slice(0, index + 1).reduce((total, segment) => {
                  return (
                    total +
                    (segment.seasonSegmentProgresses[0]?.totalPoints || 0)
                  );
                }, 0)}
              </div>
            </>
          )}

          {expandedSegment === segment.segmentNumber && (
            <SegmentDetails
              index={index}
              seasonSegments={seasonSegments}
              segment={segment}
              ongoingSegment={ongoingSegment}
              calculateTimeLeft={calculateTimeLeft}
            />
          )}
        </div>
      ) : (
        // segment has not started yet...
        <div className={`${Styles.stats} ${Styles.upcoming}`}>
          <div className={Styles.title}>
            <h4>Week {segment.segmentNumber}</h4>
          </div>

          <div className={Styles.challenges}>
            <div className={Styles.challenge}>
              <Racer />
            </div>
            <div className={Styles.challenge}>
              <SpeedCage />
            </div>
          </div>

          <div className={Styles.streaks}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>

          <div className={Styles.upcomingTimeLeft}>
            {Object.keys(calculateTimeLeft(segment.startDate)).length > 0 ? (
              <div>
                {calculateTimeLeft(segment.startDate).days}
                {"d "}
                {calculateTimeLeft(segment.startDate).hours}
                {"h "}
                {calculateTimeLeft(segment.startDate).minutes}
                {"m "}
                {calculateTimeLeft(segment.startDate).seconds}
                {"s"}
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Segment;
