import React, { useState, useEffect } from "react";
import { useAuthState, useAuthDispatch, logout } from "../../Context";
import { useQuery, useQueryClient } from "react-query";
import { Link } from "react-router-dom";

import Challenge from "../../Assets/Challenge.js";
import Dumbbell from "../../Assets/Dumbbell.js";
import Leaderboard from "../../Assets/Leaderboard.js";
// import { RiVipCrownLine } from "react-icons/ri";
import Settings from "../../Assets/Settings.js";

import Layout from "../../Components/Layout/Layout";
import GlobalProgress from "../../Components/GlobalProgress/GlobalProgress";
import WorkoutHighTouchRight from "../../Assets/WorkoutHighTouchRight.svg";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import { IoCheckmarkSharp } from "react-icons/io5";
import Segment from "./Segment.js";
import Styles from "./Season.module.scss";

import { useTranslation } from "react-i18next";

import ROOT_URL from "../../Config/rootUrl";

const Season = () => {
  const userDetails = useAuthState();
  const dispatch = useAuthDispatch();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const [timeLeft, setTimeLeft] = useState({});
  const [seasonTimeLeft, setSeasonTimeLeft] = useState({});

  const { data, isLoading, isError } = useQuery(
    ["getSeasonProgress"],
    async () => {
      const token = userDetails?.token;
      if (!token) return;
      const response = await fetch(`${ROOT_URL}/api/v1/season/progress`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 404) {
        return { message: "No ongoing season found." };
      }

      const responseJson = await response.json();
      if (responseJson?.error?.name === "TokenExpiredError") {
        logout(dispatch);
      }
      return responseJson;
    }
  );

  const seasonRank = useQuery(
    ["getSeasonRank", data?.id],
    async () => {
      const token = userDetails?.token;
      if (!token) return;
      const response = await fetch(
        `${ROOT_URL}/api/v1/season/${data.id}/user-rank`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 404) {
        return { message: "No ranking found." };
      }

      const responseJson = await response.json();
      if (responseJson?.error?.name === "TokenExpiredError") {
        logout(dispatch);
      }
      return responseJson;
    },
    {
      enabled: !!data?.id,
    }
  );

  const globalProgress = useQuery(["getGlobalProgress"], async () => {
    const token = userDetails?.token;
    if (!token) return;
    const response = await fetch(`${ROOT_URL}/api/v1/global-progress/read/`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        progress: "global-progress",
      }),
    });

    const responseJson = await response.json();
    if (responseJson?.error?.name === "TokenExpiredError") {
      logout(dispatch);
    }
    return responseJson;
  });

  useEffect(() => {
    if (data && !data.message) {
      const startTimerForSegment = (segment) => {
        setTimeLeft(calculateTimeLeft(segment.endDate));
        const timer = setInterval(() => {
          const newTimeLeft = calculateTimeLeft(segment.endDate);
          setTimeLeft(newTimeLeft);

          if (Object.keys(newTimeLeft).length === 0) {
            clearInterval(timer); // Stop the timer for the current segment
            queryClient.invalidateQueries(["getSeasonProgress"]); // Refresh data

            // Find the next ongoing segment
            const nextOngoingSegment = findOngoingSegment(data.seasonSegments);
            if (nextOngoingSegment) {
              startTimerForSegment(nextOngoingSegment); // Start the timer for the next segment
            }
          }
        }, 1000);

        return timer;
      };

      // Find the initial ongoing segment and start the timer
      const ongoingSegment = findOngoingSegment(data.seasonSegments);
      if (ongoingSegment) {
        const timer = startTimerForSegment(ongoingSegment);
        return () => clearInterval(timer); // Cleanup on unmount or data change
      }
    }
  }, [data, queryClient]);

  useEffect(() => {
    if (data && data.endDate) {
      const timer = setInterval(() => {
        const newSeasonTimeLeft = calculateTimeLeft(data.endDate);
        setSeasonTimeLeft(newSeasonTimeLeft);

        if (Object.keys(newSeasonTimeLeft).length === 0) {
          clearInterval(timer);
        }
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [data]);

  const calculateTimeLeft = (endDate) => {
    if (!endDate) return {};

    const difference = new Date(endDate) - new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const findOngoingSegment = (segments) => {
    const currentDate = new Date();
    const ongoingSegment = segments.find((segment) => {
      const startDate = new Date(segment.startDate);
      const endDate = new Date(segment.endDate);
      return currentDate >= startDate && currentDate <= endDate;
    });
    return ongoingSegment || null;
  };

  if (isLoading) return <LoadingSpinner />;

  if (!data || isError || data?.message === "No ongoing season found.") {
    return (
      <Layout>
        <div className={Styles.pageHeader}>
          {userDetails?.user?.role && userDetails.user.role === "coach" ? (
            <div className={Styles.userRole}>
              <object
                className={Styles.svgAnimation}
                type="image/svg+xml"
                data={WorkoutHighTouchRight}
                aria-label="animation"
              />
              <p>[ExerCube] Coach</p>
            </div>
          ) : null}

          <h1>{data?.username || userDetails?.user?.username}</h1>

          {!!globalProgress?.data && !globalProgress?.data?.error && (
            <GlobalProgress data={globalProgress.data} />
          )}
        </div>
        <div className={Styles.noSeason}>
          <p>
            No season data found.
            <br />
            Please try again later.
          </p>
        </div>
        <div className={Styles.menu}>
          <div className={Styles.buttonGroup}>
            <Link className={Styles.button} to="/workouts">
              <Dumbbell /> {t("common.menu.workouts")}
            </Link>

            {/* <Link className={Styles.button} to="/season">
              <RiVipCrownLine /> {"Season (beta)"}
            </Link> */}

            <Link className={Styles.button} to="/leaderboards">
              <Leaderboard /> {t("common.menu.leaderboards")}
            </Link>

            <Link className={Styles.button} to="/challenges">
              <Challenge /> {t("common.menu.challenges")}
            </Link>

            <Link className={Styles.button} to="/settings">
              <Settings /> {t("common.menu.settings")}
            </Link>
          </div>
        </div>
      </Layout>
    );
  }

  const { name, description, flavourImage, seasonSegments } = data;

  // Find the ongoing segment based on the current date
  const ongoingSegment = findOngoingSegment(seasonSegments);

  return (
    <Layout>
      <div className={Styles.pageHeader}>
        {userDetails?.user?.role && userDetails.user.role === "coach" ? (
          <div className={Styles.userRole}>
            <object
              className={Styles.svgAnimation}
              type="image/svg+xml"
              data={WorkoutHighTouchRight}
              aria-label="animation"
            />
            <p>[ExerCube] Coach</p>
          </div>
        ) : null}

        <h1>{data?.username || userDetails?.user?.username}</h1>

        {!!globalProgress?.data && !globalProgress?.data?.error && (
          <GlobalProgress data={globalProgress.data} />
        )}
      </div>

      <div className={Styles.seasonContainer}>
        {flavourImage && <img src={`${ROOT_URL}${flavourImage}`} alt="" />}

        <div className={Styles.seasonOverlay}>
          {description && (
            <h5>
              <span className={Styles.teal}>{description}</span>
            </h5>
          )}
          {name && (
            <h2>
              <span className={Styles.purple}>{name}</span>
            </h2>
          )}

          {ongoingSegment && (
            <>
              <div className={Styles.challengeContainer}>
                {ongoingSegment?.seasonChallenges?.map((challenge) => (
                  <div key={challenge.id} className={Styles.challenge}>
                    <div>
                      <h5>{challenge.challengeType}</h5>
                      <p>{challenge.challengeConfig.name}</p>
                    </div>
                    {challenge.seasonChallengeProgresses.some(
                      (progress) => progress.completedAt
                    ) ? (
                      <div className={Styles.challengeCompleted}>
                        <p>
                          <IoCheckmarkSharp />
                          <span className={Styles.claimed}>Claimed</span>
                        </p>
                      </div>
                    ) : (
                      <div className={Styles.challengeOpen}>
                        <p>
                          <span className={Styles.emoji}>&#128274;</span>
                          <span className={Styles.expAmount}>+400</span>
                          <span className={Styles.expName}>EXP</span>
                        </p>
                      </div>
                    )}
                  </div>
                ))}
              </div>
              {Object.keys(timeLeft).length > 0 && (
                <div className={Styles.timer}>
                  <span className={Styles.timerText}>Time remaining: </span>
                  <span className={Styles.timerTime}>
                    {timeLeft.days} {timeLeft.days === 1 ? "day" : "days"}{" "}
                    {timeLeft.hours} {timeLeft.hours === 1 ? "hour" : "hours"}{" "}
                    {timeLeft.minutes}{" "}
                    {timeLeft.minutes === 1 ? "minute" : "minutes"}{" "}
                    {timeLeft.seconds}{" "}
                    {timeLeft.seconds === 1 ? "second" : "seconds"}
                  </span>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <div className={Styles.progress}>
        <h4>Your current progress</h4>

        <div className={Styles.seasonProgress}>
          <div className={Styles.points}>
            <h6>Points</h6>
            <div className={Styles.seasonPoints}>
              {data.seasonSegments.reduce((total, segment) => {
                return (
                  total + (segment.seasonSegmentProgresses[0]?.totalPoints || 0)
                );
              }, 0)}
            </div>
          </div>

          <div className={Styles.rank}>
            <h6>Rank</h6>
            <div className={Styles.seasonRank}>
              {seasonRank?.data?.rank ? seasonRank.data.rank : "–"}
            </div>
          </div>

          <div className={Styles.seasonEnds}>
            <div className={Styles.seasonEndsTitle}>Season ends:</div>
            {Object.keys(seasonTimeLeft).length > 0 ? (
              <div>
                {seasonTimeLeft.days}
                {"d "}
                {seasonTimeLeft.hours}
                {"h "}
                {seasonTimeLeft.minutes}
                {"m "}
                {seasonTimeLeft.seconds}
                {"s"}
              </div>
            ) : Object.keys(seasonTimeLeft).length === 0 && data.endDate ? (
              <div>Calculating...</div>
            ) : (
              <div>The season has ended.</div>
            )}
          </div>
        </div>

        {ongoingSegment &&
          data.seasonSegments.map((segment, index) => (
            <Segment
              seasonSegments={seasonSegments}
              ongoingSegment={ongoingSegment}
              segment={segment}
              index={index}
              key={segment.id}
            />
          ))}
      </div>

      {/* <pre style={{ fontSize: 7 }}>{JSON.stringify(data, null, 4)}</pre> */}
    </Layout>
  );
};

export default Season;
