import React from "react";
import { Link } from "react-router-dom";
import { useAuthDispatch, useAuthState, logout } from "../../Context";
import Challenge from "../../Assets/Challenge.js";
// import Community from "../../Assets/Community.js";
import Dumbbell from "../../Assets/Dumbbell.js";
import Home from "../../Assets/Home.js";
import Leaderboard from "../../Assets/Leaderboard.js";
// import Progress from "../../Assets/Progress.js";
import { RiVipCrownLine } from "react-icons/ri";
import Settings from "../../Assets/Settings.js";
import { AiOutlineLogout } from "react-icons/ai";
// import { AiOutlineUser } from "react-icons/ai";
import { AiOutlineDatabase } from "react-icons/ai";
import { ImQrcode } from "react-icons/im";
import { BsKey } from "react-icons/bs";
import { AiOutlineUnlock } from "react-icons/ai";
import { AiOutlineBook } from "react-icons/ai";
import Styles from "./Layout.module.scss";
import { useTranslation } from "react-i18next";

const Menu = ({ setMenuOpen }) => {
  const { user } = useAuthState();
  const dispatch = useAuthDispatch();
  const { t } = useTranslation();

  const handleLogout = () => {
    logout(dispatch);
  };

  return (
    <div className={Styles.menu}>
      <div className={Styles.buttonGroup}>
        <Link
          className={Styles.button}
          to="/"
          onClick={() => setMenuOpen(false)}
        >
          <Home /> {t("common.menu.exerhub")}
        </Link>

        <Link
          className={Styles.button}
          to="/season"
          onClick={() => setMenuOpen(false)}
        >
          <RiVipCrownLine /> {"Season"}
        </Link>

        <Link
          className={Styles.button}
          to="/workouts"
          onClick={() => setMenuOpen(false)}
        >
          <Dumbbell /> {t("common.menu.workouts")}
        </Link>

        {/* <Link
          className={Styles.button}
          to="/progress"
          onClick={() => setMenuOpen(false)}
        >
          <Progress /> {t("common.menu.progress")}
        </Link> */}

        <Link
          className={Styles.button}
          to="/leaderboards"
          onClick={() => setMenuOpen(false)}
        >
          <Leaderboard /> {t("common.menu.leaderboards")}
        </Link>

        <Link
          className={Styles.button}
          to="/challenges"
          onClick={() => setMenuOpen(false)}
        >
          <Challenge /> {t("common.menu.challenges")}
        </Link>

        <Link
          className={Styles.button}
          to="/settings"
          onClick={() => setMenuOpen(false)}
        >
          <Settings /> {t("common.menu.settings")}
        </Link>

        <Link
          className={Styles.button}
          to="/qrscanner"
          onClick={() => setMenuOpen(false)}
        >
          <ImQrcode /> {t("common.menu.qrscanner")}
        </Link>

        {user.role === "admin" && (
          <Link
            className={Styles.button}
            to="/crud/users"
            onClick={() => setMenuOpen(false)}
          >
            <AiOutlineDatabase /> {t("common.menu.crud")}
          </Link>
        )}

        {user.role === "admin" && (
          <Link
            className={Styles.button}
            to="/generate-licensekeys"
            onClick={() => setMenuOpen(false)}
          >
            <BsKey /> Generate Keys
          </Link>
        )}

        {user.role === "trainee" && (
          <Link
            className={Styles.button}
            to="/ausbildung"
            onClick={() => setMenuOpen(false)}
          >
            <AiOutlineBook /> Ausbildung
          </Link>
        )}

        <Link
          className={Styles.button}
          to="/activate-licensekey"
          onClick={() => setMenuOpen(false)}
        >
          <AiOutlineUnlock /> Activate Key
        </Link>

        <button onClick={handleLogout}>
          <AiOutlineLogout /> {t("common.menu.logout")}
        </button>
      </div>
    </div>
  );
};

export default Menu;
