import React from "react";

import { MdCheckBox } from "react-icons/md";
import { MdCheckBoxOutlineBlank } from "react-icons/md";

import ChallengeRank from "./ChallengeRank.js";

import Styles from "./SegmentDetails.module.scss";

const SegmentDetails = ({
  index,
  seasonSegments,
  segment,
  ongoingSegment,
  calculateTimeLeft,
}) => {
  return (
    <div className={Styles.segmentDetails}>
      <div className={Styles.challengeStatus}>
        <div className={Styles.info}>
          {segment.seasonChallenges.find(
            (challenge) =>
              challenge.challengeType === "racer" &&
              challenge.seasonChallengeProgresses.length > 0
          ) ? (
            <>
              <div className={`${Styles.singleChallenge} ${Styles.active}`}>
                <MdCheckBox />
                Complete Racer Challenge
              </div>
              <div className={`${Styles.singleAddedPoints} ${Styles.active}`}>
                + 10
              </div>
            </>
          ) : (
            <>
              <div className={Styles.singleChallenge}>
                <MdCheckBoxOutlineBlank />
                Complete Racer Challenge
              </div>
              <div className={Styles.singleAddedPoints}>+ 10</div>
            </>
          )}
        </div>
      </div>

      <div className={Styles.challengeStatus}>
        <div className={Styles.info}>
          {segment.seasonChallenges.find(
            (challenge) =>
              challenge.challengeType === "speedCage" &&
              challenge.seasonChallengeProgresses.length > 0
          ) ? (
            <>
              <div className={`${Styles.singleChallenge} ${Styles.active}`}>
                <MdCheckBox />
                Complete SpeedCage Challenge
              </div>
              <div className={`${Styles.singleAddedPoints} ${Styles.active}`}>
                + 10
              </div>
            </>
          ) : (
            <>
              <div className={Styles.singleChallenge}>
                <MdCheckBoxOutlineBlank />
                Complete SpeedCage Challenge
              </div>
              <div className={Styles.singleAddedPoints}>+ 10</div>
            </>
          )}
        </div>
      </div>

      <div className={Styles.challengeStatus}>
        <div className={Styles.info}>
          {segment.seasonSegmentProgresses[0]?.streakCompleted ? (
            <>
              <div className={`${Styles.streakChallenge} ${Styles.active}`}>
                <MdCheckBox />
                Complete Both Challenges
              </div>
              <div className={Styles.streakAddedPoints}>
                <span
                  className={`${
                    segment.seasonSegmentProgresses[0].streakPoints === 10
                      ? Styles.active
                      : ""
                  }`}
                >
                  +&nbsp;10 /
                </span>{" "}
                <span
                  className={`${
                    segment.seasonSegmentProgresses[0].streakPoints === 20
                      ? Styles.active
                      : ""
                  }`}
                >
                  +&nbsp;20 /
                </span>{" "}
                <span
                  className={`${
                    segment.seasonSegmentProgresses[0].streakPoints === 35
                      ? Styles.active
                      : ""
                  }`}
                >
                  +&nbsp;35 /
                </span>{" "}
                <span
                  className={`${
                    segment.seasonSegmentProgresses[0].streakPoints === 50
                      ? Styles.active
                      : ""
                  }`}
                >
                  +&nbsp;50
                </span>
              </div>
            </>
          ) : (
            <>
              <div className={Styles.streakChallenge}>
                <MdCheckBoxOutlineBlank />
                Complete Both Challenges
              </div>

              <div className={Styles.streakAddedPoints}>
                <span>+ 10</span> /<span>+ 20</span> /<span>+ 35</span> /
                <span>+ 50</span>
              </div>
            </>
          )}
        </div>
      </div>

      <div className={`${Styles.addedRankingPoints} ${Styles.marginTop}`}>
        <div className={Styles.title}>
          <h4>SpeedCage Rank</h4>
        </div>

        {segment.seasonChallenges?.find(
          (challenge) =>
            challenge.challengeType === "speedCage" &&
            challenge.seasonChallengeProgresses.length > 0
        )?.seasonChallengeProgresses[0]?.rankingPoints ? (
          <div className={Styles.challengeRank}>
            {segment.seasonChallenges?.find(
              (challenge) =>
                challenge.challengeType === "speedCage" &&
                challenge.seasonChallengeProgresses.length > 0
            )?.seasonChallengeProgresses[0]?.seasonChallengeId ? (
              <>
                {/* {segment.segmentNumber === ongoingSegment.segmentNumber ? (
                  <div className={Styles.liveRank}>Live Rank</div>
                ) : (
                  <div></div>
                )} */}
                <ChallengeRank
                  liveRank={
                    segment.segmentNumber === ongoingSegment.segmentNumber ||
                    false
                  }
                  seasonChallengeId={
                    segment.seasonChallenges?.find(
                      (challenge) =>
                        challenge.challengeType === "speedCage" &&
                        challenge.seasonChallengeProgresses.length > 0
                    )?.seasonChallengeProgresses[0]?.seasonChallengeId
                  }
                />
              </>
            ) : null}
          </div>
        ) : (
          <div className={Styles.challengeRank}></div>
        )}

        <div
          className={`${Styles.addedPoints} ${
            segment.seasonChallenges?.find(
              (challenge) =>
                challenge.challengeType === "speedCage" &&
                challenge.seasonChallengeProgresses.length > 0
            )?.seasonChallengeProgresses[0]?.rankingPoints
              ? Styles.active
              : ""
          }`}
        >
          {segment.seasonChallenges?.find(
            (challenge) =>
              challenge.challengeType === "speedCage" &&
              challenge.seasonChallengeProgresses.length > 0
          )?.seasonChallengeProgresses[0]?.rankingPoints || "–"}
        </div>
      </div>

      <div className={Styles.addedRankingPoints}>
        <div className={Styles.title}>
          <h4>Racer Rank</h4>
        </div>

        {segment.seasonChallenges?.find(
          (challenge) =>
            challenge.challengeType === "racer" &&
            challenge.seasonChallengeProgresses.length > 0
        )?.seasonChallengeProgresses[0]?.rankingPoints ? (
          <div className={Styles.challengeRank}>
            {segment.seasonChallenges?.find(
              (challenge) =>
                challenge.challengeType === "racer" &&
                challenge.seasonChallengeProgresses.length > 0
            )?.seasonChallengeProgresses[0]?.seasonChallengeId ? (
              <>
                {/* {segment.segmentNumber === ongoingSegment.segmentNumber ? (
                  <div className={Styles.liveRank}>Live Rank</div>
                ) : (
                  <div></div>
                )} */}
                <ChallengeRank
                  liveRank={
                    segment.segmentNumber === ongoingSegment.segmentNumber ||
                    false
                  }
                  seasonChallengeId={
                    segment.seasonChallenges?.find(
                      (challenge) =>
                        challenge.challengeType === "racer" &&
                        challenge.seasonChallengeProgresses.length > 0
                    )?.seasonChallengeProgresses[0]?.seasonChallengeId
                  }
                />
              </>
            ) : null}
          </div>
        ) : (
          <div className={Styles.challengeRank}></div>
        )}

        <div
          className={`${Styles.addedPoints} ${
            segment.seasonChallenges?.find(
              (challenge) =>
                challenge.challengeType === "racer" &&
                challenge.seasonChallengeProgresses.length > 0
            )?.seasonChallengeProgresses[0]?.rankingPoints
              ? Styles.active
              : ""
          }`}
        >
          {segment.seasonChallenges?.find(
            (challenge) =>
              challenge.challengeType === "racer" &&
              challenge.seasonChallengeProgresses.length > 0
          )?.seasonChallengeProgresses[0]?.rankingPoints || "–"}
        </div>
      </div>

      <div className={`${Styles.addedRankingPoints} ${Styles.marginTop}`}>
        <div className={Styles.title}>
          <h4>Total Points</h4>
        </div>

        <div></div>
        <div className={`${Styles.addedPoints} ${Styles.active}`}>
          {segment.seasonSegmentProgresses[0]?.totalPoints || 0}
        </div>
        <div className={Styles.addedTotalPoints}>
          {seasonSegments.slice(0, index + 1).reduce((total, segment) => {
            return (
              total + (segment.seasonSegmentProgresses[0]?.totalPoints || 0)
            );
          }, 0)}
        </div>
      </div>

      {Object.keys(calculateTimeLeft(segment.endDate)).length > 0 ? (
        <div className={Styles.challengeEndsIn}>
          <div className={Styles.title}>Challenge ends in:</div>
          <div className={Styles.upcomingWeekDateTime}>
            <div>
              {calculateTimeLeft(segment.endDate).days}
              {"d "}
              {calculateTimeLeft(segment.endDate).hours}
              {"h "}
              {calculateTimeLeft(segment.endDate).minutes}
              {"m "}
              {calculateTimeLeft(segment.endDate).seconds}
              {"s"}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SegmentDetails;
